import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/uguryildirim/git/eteration/ui-components/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Playground from 'components/Playground';
import Pagination from "components/Pagination";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "pagination"
    }}>{`Pagination`}</h1>

    <Playground __position={0} __code={'<Pagination>\n  <Pagination.Item>\n    <Pagination.Link first href=\"#\" />\n  </Pagination.Item>\n  <Pagination.Item>\n    <Pagination.Link previous href=\"#\" />\n  </Pagination.Item>\n  <Pagination.Item>\n    <Pagination.Link href=\"#\">1</Pagination.Link>\n  </Pagination.Item>\n  <Pagination.Item>\n    <Pagination.Link href=\"#\">2</Pagination.Link>\n  </Pagination.Item>\n  <Pagination.Item>\n    <Pagination.Link href=\"#\">3</Pagination.Link>\n  </Pagination.Item>\n  <Pagination.Item>\n    <Pagination.Link next href=\"#\" />\n  </Pagination.Item>\n  <Pagination.Item>\n    <Pagination.Link last href=\"#\" />\n  </Pagination.Item>\n</Pagination>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Pagination,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Pagination mdxType="Pagination">
    <Pagination.Item>
      <Pagination.Link first href="#" />
    </Pagination.Item>
    <Pagination.Item>
      <Pagination.Link previous href="#" />
    </Pagination.Item>
    <Pagination.Item>
      <Pagination.Link href="#">
        1
      </Pagination.Link>
    </Pagination.Item>
    <Pagination.Item>
      <Pagination.Link href="#">
        2
      </Pagination.Link>
    </Pagination.Item>
    <Pagination.Item>
      <Pagination.Link href="#">
        3
      </Pagination.Link>
    </Pagination.Item>
    <Pagination.Item>
      <Pagination.Link next href="#" />
    </Pagination.Item>
    <Pagination.Item>
      <Pagination.Link last href="#" />
    </Pagination.Item>
  </Pagination>
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <p><a href="https://reactstrap.github.io/components/pagination/" target="_blank">Reactstrap Pagination</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      